import React from "react"

const ENV = process.env.GATSBY_ENV || "development";

export const ThirdPartyTags = () => {

  return (ENV === "production") && (<>
    {/* Old Lucky Orange snippet */}
    <script
      type="text/javascript"
      dangerouslySetInnerHTML={{
        __html: `window.__lo_site_id = 307941;(function() {var wa = document.createElement('script'); wa.type = 'text/javascript'; wa.async = true;wa.src = 'https://d10lpsik1i8c69.cloudfront.net/w.js';var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(wa, s);})();`
      }}
    />
    {/* New Lucky Orange snippet */}
    <script async defer src="https://tools.luckyorange.com/core/lo.js?site-id=386d433d"></script>
  </>);
};
