import styled from "styled-components"
import HeroImg from "../images/hero.png"
import HeroMobileImg from "../images/hero_mobile.png"
import BlurBG from "../images/blurred_bg.png"
import BlurMobileBG from "../images/blurred_bg_mobile.png"
import MemberBG from "../images/members.png"
import MemberBGIpad from "../images/members-ipad.png"
import CrewBG from "../images/crew.png"
import BusinessBG from "../images/biz.png"
export const ContainerWrapper = styled.div`
  position: relative;
  sup {
    font-size: 100%;
  }
  .absolute {
    position: fixed;
    z-index: 999;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .container {
    width: 100%;
    max-width: 1180px;
    margin: auto;
    overflow-x: hidden;
    position: relative;
  }
  .flex {
    display: flex;
  }

  .space-between {
    justify-content: space-between;
  }

  .mobile {
    display: none;
  }

  .top-wrapper {
    background: url(${HeroImg}) left top no-repeat;
    background-size: cover;
    position: relative;
    z-index: 9;
    .login {
      position: absolute;
      right: 15px;
      top: 40px;
      color: #6fe6ae;
      text-decoration: none;
      font-size: 20px;
    }
    .flex {
      justify-content: space-between;
    }
    .intro {
      padding: 300px 0 500px;
      h1 {
        img {
          width: 100%;
          max-width: 400px;
        }
        font-size: 100px;
        font-weight: 500;
        margin-bottom: 0 !important;
        color: #fff;
        text-transform: uppercase;
        line-height: 55px;
        span {
          font-size: 50px;
          letter-spacing: 21px;
        }
      }
      p {
        color: #fff;
        font-size: 26px;
        width: 100%;
        max-width: 435px;
        span {
          color: #6fe6ae;
          font-size: 16px;
          letter-spacing: 2px;
          text-transform: uppercase;
          font-weight: bold;
        }
      }
      .learnmore {
        position: absolute;
        bottom: 25%;
        img {
          cursor: pointer;
          margin-top: 20px;
        }
      }
    }
    .form-wrapper {
      margin-top: 10%;
      border: 1px solid rgba(255, 255, 255, 0.3);
      padding: 30px;
      width: 100%;
      max-height: 680px;
      max-width: 430px;
      backdrop-filter: blur(5px);
      .MuiFormGroup-root {
        flex-direction: row;
        flex-wrap: nowrap;
        label {
          width: 55%;
        }
        .MuiFormControlLabel-root {
          margin-right: 0;
        }
      }
      .MuiCheckbox-colorSecondary {
        color: #fff;
        &.Mui-checked {
          color: #fff;
        }
      }

      .flex {
        .firstname {
          margin-right: 25px;
        }
      }
      p,
      label {
        color: #fff;
      }
      p {
        font-size: 24px;
        &.describe {
          font-size: 24px;
          margin: 10px 0;
        }
      }
      label {
        display: block;
        width: 100%;
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 10px;
        span {
          font-size: 14px;
        }
      }
      input[type="text"],
      input[type="email"] {
        background: none;
        width: 100%;
        color: #fff;
        border: 1px solid #fff;
        padding: 0 10px;
        height: 45px;
        outline: 0;
        margin-bottom: 15px;
      }
      .hidden {
        opacity: 0;
      }
      ul {
        li {
          list-style: none;
          display: inline-flex;
        }
      }
      input[type="submit"] {
        background: #6fe6ae;
        border-radius: 31px;
        width: 100%;
        border: 0;
        height: 60px;
        margin-top: 20px;
        font-size: 20px;
        font-weight: 700;
      }
    }
  }

  .diversity-wrapper {
    position: relative;
    z-index: 8;
    background: url(${BlurBG}) left top no-repeat;
    margin-top: -900px;
    padding-top: 750px;
    section {
      h2 {
        font-size: 90px;
        color: #fff;
        font-weight: 500;
      }
      p {
        color: #6fe6ae;
        font-size: 16px;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: 700;
      }
      &.diversity {
        p {
          margin-left: 50px;
          padding-top: 25px;
        }
      }
      &.accountability,
      &.human {
        padding-left: 80px;
      }
      &.platform {
        padding-left: 100px;
        p {
          text-align: right;
          margin-right: 30px;
          padding-top: 30px;
        }
      }
    }
    .bottom {
      p {
        color: #fff;
        width: 100%;
        max-width: 420px;
        margin-right: 30px;
        font-size: 22px;
      }
    }
    .positive-impact {
      margin-top: 50px;
      border-top: 1px solid rgba(255, 255, 255, 0.5);
      border-bottom: 1px solid rgba(255, 255, 255, 0.5);
      padding: 70px 0 50px;
      .flex {
        justify-content: space-between;
        align-items: center;
      }
      p {
        width: 100%;
        max-width: 580px;
        font-weight: bold;
        font-size: 16px;
        span {
          color: #fff;
          font-size: 26px;
          line-height: 150%;
          text-transform: none;
          font-weight: normal;
        }
      }
      .img-container {
        position: relative;
        img {
          width: 100%;
          max-width: 390px;
          &.sun {
            max-width: 98px;
            position: absolute;
            z-index: 9;
            margin: auto;
            top: 0;
            bottom: 0;
            left: -50px;
          }
        }
      }
    }
  }
  .members {
    padding: 50px 0 100px;
    position: relative;
    .container {
      background: url(${MemberBG}) left top no-repeat;
    }
    .flex {
      justify-content: space-between;
    }
    h3 {
      position: absolute;
      color: #fff;
      transform: rotate(90deg);
      font-size: 80px;
      top: 320px;
      left: -105px;
      margin: 0;
    }
    p {
      color: #fff;
      width: 100%;
      max-width: 435px;
      padding-top: 620px;
      font-size: 22px;
      &.faq-text {
        position: absolute;
        bottom: 0;
        text-align: center;
        width: 100%;
        max-width: 100%;
        @media screen and (max-width: 767px) {
          font-size: 15px;
        }
      }
    }
    .crew {
      position: relative;
    }
    .biz {
      position: relative;
      h3 {
        left: -175px;
        top: 580px;
      }
      p {
        padding-top: 850px;
      }
    }
  }
  .signup {
    background: #6fe6ae;
    padding: 20px 0 0;
    .flex {
      justify-content: space-between;
    }
    button {
      border-radius: 31px;
      background: #fff;
      border: 0;
      width: 100%;
      max-width: 334px;
      height: 62px;
      font-size: 20px;
      margin-top: 5px;
      font-weight: 700;
    }
    .share {
      padding-top: 15px;
      p {
        line-height: 52px;
        margin-right: 20px;
        font-size: 20px;
        font-weight: 700;
      }
      button {
        border-radius: 50%;
        width: 52px;
        height: 52px;
        text-align: center;
        margin: 0 10px;
        line-height: 52px;
        &.fb {
          background-color: #ffc200 !important;
        }
        &.email {
          background-color: #f2ae89 !important;
        }
        &.twitter {
          background-color: #00b3ec !important;
        }
      }
    }
  }
  footer {
    background: #d8d8d8;
    padding: 100px 0;
    .top-links {
      ul {
        padding: 0 150px 0 0;
        margin-left: 0;
        li {
          list-style: none;
          a {
            color: #000;
            font-weight: bold;
            text-decoration: none;
          }
        }
      }
    }
    p {
      font-size: 14px;
      font-weight: 700;
      text-transform: uppercase;
      span {
        padding-left: 50px;
        a {
          color: #000;
          margin-right: 30px;
          text-transform: none;
          text-decoration: none;
        }
      }
    }
  }

  .checkbox {
    display: flex !important;
    margin-left: 2px;
    font-size: 2rem;
    color: #6fe6ae;
    cursor: pointer;
    .radio__label {
      font-weight: 400;
      padding-left: 13px;
      position: relative;
      font-size: 16px !important;
      top: -4px;
      a {
        color: #fff;
        text-decoration: none;
        border-bottom: 1px solid #fff;
        padding-bottom: 1px;
        &:hover {
          color: #6fe6ae;
          border-bottom: 1px solid #6fe6ae;
        }
      }
    }
  }

  .checkbox__control {
    display: inline-grid;
    width: 1em;
    height: 1em;
    border-radius: 0;
    border: 0.1em solid #fff;

    svg {
      transition: transform 0.1s ease-in 25ms;
      transform: scale(0);
      transform-origin: bottom left;
    }
  }

  .checkbox__input {
    display: grid;
    grid-template-areas: "checkbox";

    > * {
      grid-area: checkbox;
    }

    input {
      opacity: 0;
      width: 1em;
      height: 1em;

      &:checked + .checkbox__control svg {
        transform: scale(1);
      }
    }
  }

  @media screen and (max-width: 991px) {
    .top-wrapper {
      background: url(${HeroImg}) center top no-repeat;
    }
  }

  @media screen and (max-width: 885px) {
    .top-wrapper {
      .intro {
        padding-right: 20px;
        h1 {
          font-size: 80px;
          span {
            letter-spacing: 10px;
          }
        }
      }
      .form-wrapper {
        padding: 20px 15px;
        p {
          font-size: 20px;
          &.describe {
            font-size: 20px;
          }
        }
      }
    }
    .diversity-wrapper {
      section {
        h2 {
          font-size: 70px;
        }
      }
    }
    .members {
      .container {
        background: url(${MemberBGIpad}) left top no-repeat;
      }
    }
  }

  @media screen and (max-width: 767px) {
    .desktop {
      display: none;
    }
    .mobile {
      display: block;
    }
    .container {
      padding: 20px;
      max-width: 100% !important;
    }

    .mobile-bg {
      position: relative;
      .top-bg {
        position: absolute;
        top: 30%;
        right: 0;
      }
    }
    .top-wrapper {
      background: url(${HeroMobileImg}) center top no-repeat;
      .flex {
        display: block;
      }
      .intro {
        padding: 27% 0 20px;
        h1 {
          font-size: 60px;
          line-height: 40px;
          span {
            font-size: 34px;
          }
        }
        p {
          font-size: 20px;
          span {
            font-size: 14px;
          }
        }
      }
      .form-wrapper {
        max-width: 100%;
        padding: 30px 20px;
        max-height: 100%;
        .flex {
          .firstname {
            margin-right: 0;
          }
        }
        p {
          font-size: 18px;
          &.describe {
            font-size: 18px;
          }
        }
        label {
          font-size: 16px;
        }
        .MuiFormGroup-root {
          flex-direction: column;
          label {
            width: 100%;
          }
        }
        input[type="submit"] {
          height: 50px;
        }
      }
      .learnmore {
        position: relative;
        text-align: center;
        margin-top: 30px;
        z-index: 999;
        img {
          width: 63px;
        }
      }
    }
    .diversity-wrapper {
      margin-top: 0;
      padding-top: 0;
      z-index: 10;
      background: url(${BlurMobileBG}) left top no-repeat;

      h2 {
        font-size: 48px !important;
      }
      .bottom {
        p {
          text-transform: none;
          font-size: 20px;
          font-weight: 400;
        }
      }
      .positive-impact {
        p {
          span {
            font-size: 24px;
          }
        }
        .img-container {
          position: relative;
          img {
            max-width: 100%;
            &.sun {
              margin: 0 auto;
              left: 0;
              right: 0;
              bottom: 14px;
              top: auto;
            }
          }
        }
      }
    }

    .members {
      .container {
        background: none;
        max-width: 100%;
        padding: 0;
      }
      .flex {
        display: block;
      }
      h3 {
        top: 335px;
        left: -75px;
        font-size: 70px;
        font-weight: 400;
      }
      p {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 600px;
        font-size: 20px;
      }

      .crew {
        background: url(${CrewBG}) left top no-repeat;
      }
      .biz {
        background: url(${BusinessBG}) right top no-repeat;
        h3 {
          left: -135px;
          top: 350px;
        }
        p {
          padding-top: 600px;
        }
      }
    }

    .signup {
      text-align: center;
      padding: 5px 0 0;
      .container {
        padding: 10px 20px 0;
      }
      .flex {
        display: block;
      }
      button {
        height: 50px;
        font-weight: 400;
      }
      .share {
        display: flex;
        padding-top: 10px;
        margin: 15px auto 0;
        width: 100%;
        max-width: 334px;
        p {
          font-size: 20px;
          font-weight: 700;
          line-height: 40px;
        }
        button {
          width: 40px;
          height: 40px;
          margin: 0 5px;
          line-height: 40px;
          &.fb {
            svg {
              height: 26px;
            }
          }
          &.email {
            svg {
              position: relative;
              top: -1px;
              height: 15px;
            }
          }
          &.twitter {
            svg {
              position: relative;
              top: -1px;
              height: 18px;
            }
          }
        }
      }
    }
    footer {
      padding: 50px 0;
      .flex {
        flex-wrap: wrap;
      }
      p {
        span {
          display: block;
          padding-left: 0;
          padding-top: 30px;
        }
      }
    }
  }

  .faq {
    background: #fff;
    .answer {
      padding-bottom: 100px;
    }
    .video-wrapper {
      background: #6fe6ae;
      padding: 80px 0;
      margin-bottom: 50px;
      .flex {
        display: flex;
        @media screen and (max-width: 767px) {
          display: block;
        }
        .video-container {
          width: 100%;
          max-width: 60%;
          @media screen and (max-width: 767px) {
            max-width: 100%;
          }
          video {
            width: 100%;
            height: 100%;
          }
        }
        .video-list {
          border: 1px solid #000;
          margin-left: 50px;
          padding: 50px 30px 25px;
          width: 35%;
          @media screen and (max-width: 767px) {
            width: 100%;
            margin-left: 0;
            margin-top: 40px;
          }
          p {
            color: #000;
            cursor: pointer;
            font-weight: bold;
            margin-bottom: 15px;
            font-size: 17px;
            transition: 0.3s;
            &.active,
            &:hover {
              color: #fff;
            }
          }
          h4 {
            border-bottom: 1px solid #000;
            padding-bottom: 10px;
            margin-bottom: 30px;
          }
        }
      }
    }
    h1 {
      text-align: center;
      margin-bottom: 30px;
    }
    h3 {
      margin-top: 40px;
    }
    u {
      &.pop {
        cursor: pointer;
      }
    }
    .MuiAccordion-rounded {
      margin-bottom: 10px;
      box-shadow: none;
    }
    .MuiAccordionSummary-root {
      background: #6fe6ae;
    }
    .MuiCollapse-wrapper {
      border: 2px solid #6fe6ae;
    }
  }

  .bg {
    min-height: 100vh;
    height: 100%;
    background-image: radial-gradient(
      circle at bottom left,
      rgb(0, 41, 27) 0%,
      rgb(1, 22, 16) 25%,
      rgb(0, 0, 0) 145%
    );
  }

  .login-page {
    .top {
      text-align: center;
      padding-top: 50px;
      p {
        color: #fff;
        text-transform: uppercase;
        font-size: 18px;
      }
    }
    .form-wrapper {
      margin: 50px auto auto;
      width: 100%;
      max-width: 500px;
      .flex {
        margin-top: 20px;
        &.actions {
          margin-top: 30px;
          a {
            color: #fff;
            text-decoration: none;
            font-size: 18px;
            margin-right: 30px;
            position: relative;
            top: 5px;
          }
          button {
            background: #6fe6ae;
            color: #000;
            width: 100%;
            max-width: 120px;
            &.reset {
              max-width: 200px;
            }
          }
          .flex {
            width: 100%;
            justify-content: flex-end;
          }
          &.reset {
            float: right;
            margin-top: 50px;
          }
        }
      }
      label {
        width: 250px;
        color: #fff;
        text-transform: uppercase;
        text-align: right;
        margin-top: 20px;
        margin-right: 30px;
        font-weight: 700;
      }
      input {
        width: 100%;
        background: #fff;
        padding-left: 15px;
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
    .mfa-login {
      width: 100%;
      max-width: 70%;
      margin: 60px auto 30px;
      h2,
      p,
      a {
        color: #fff;
      }
      h2 {
        font-size: 40px;
        @media screen and (max-width: 767px) {
          font-size: 20px;
        }
      }
      a {
        text-decoration: none;
      }
      .form {
        margin-top: 60px;
        input {
          width: 100%;
          background: #fff;
          padding-left: 15px;
          padding-top: 10px;
          padding-bottom: 10px;
        }
        p {
          color: #fff;
          font-size: 14px;
          margin-top: 20px;
        }
        .actions {
          text-align: right;
          button {
            text-transform: none;
            &.submit {
              background: #6fe6ae;
              margin-left: 15px;
            }
          }
        }
      }
    }
  }
`

export const Modal = styled.div`
  .close {
    float: right;
    cursor: pointer;
  }
  p {
    font-size: 13px;
    margin-bottom: 15px;
    span {
      font-weight: 700;
    }
  }
  ul {
    li {
      margin-bottom: 10px;
      font-size: 13px;
      list-style: none;
      span {
        font-weight: 700;
      }
    }
  }
`
export default {}
