import * as React from "react"
import PropTypes from "prop-types"
import { ContainerWrapper } from "./style"
import "./layout.css"
import PDF from "../pdf/FTWInvokePrivacyPolicyV8.pdf"
import { ThirdPartyTags } from "./thirdPartyTags"
import { GtagTags } from "./gtagTags"
const Layout = ({ children, login }) => {
  return (
    <ContainerWrapper>
      <GtagTags />
      <main>{children}</main>
      {!login && (
        <footer>
          <div className="container">
            <p>More about FREE THE WORK &reg;</p>
            <div className="flex top-links">
              <ul>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://freethework.com/about"
                  >
                    About
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://freethework.com/press"
                  >
                    Press
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://freethework.com/culture"
                  >
                    Culture
                  </a>
                </li>
              </ul>
              <ul>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://freethework.com/emerging-creators-listing"
                  >
                    Emerging Creators
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://freethework.com/professional-creators"
                  >
                    Professional Creators
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://freethework.com/businesses"
                  >
                    Businesses
                  </a>
                </li>
              </ul>
              <ul>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://freethework.com/contact"
                  >
                    Contact
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://freethework.com/donate"
                  >
                    Donate
                  </a>
                </li>
                <li>
                  <a href="https://localizejs.com?utm=branded">
                    <img
                      style={{
                        border: "1px solid #696d71",
                        borderRadius: 3,
                        width: 90,
                      }}
                      src="https://global.localizecdn.com/0-images/branded/branded-localize.png"
                      alt="Website Localization"
                      target="_blank"
                      rel="noreferrer"
                    />
                  </a>
                </li>
              </ul>
            </div>
            <p>
              {new Date().getFullYear()} Invoke <sup>&trade;</sup>
              <span>
                <a href={PDF} target="_blank " rel="noreferrer">
                  Privacy
                </a>
              </span>
              <span>
                <a
                  href="https://freethework.com/terms-conditions"
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms of Use
                </a>
              </span>
            </p>
          </div>
        </footer>
      )}

      <ThirdPartyTags />
    </ContainerWrapper>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
