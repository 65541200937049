import React from "react"
import { ENV } from "gatsby-env-variables"

export const GtagTags = () => {
  if (ENV === "prod") {
    return (
      <>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-CRH1XDJVLX"
        ></script>
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: `window.dataLayer = window.dataLayer || [];
            function gtag(){
              dataLayer.push(arguments)
            }
            gtag('js', new Date());
            gtag('config', 'G-CRH1XDJVLX');`,
          }}
        />
      </>
    )
  } else {
    return (
      <>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-EVNNS8HXNY"
        ></script>
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: `window.dataLayer = window.dataLayer || []; 
            function gtag() {
              dataLayer.push(arguments)
            }
            gtag('js', new Date()); gtag('config', 'G-EVNNS8HXNY');`,
          }}
        />
      </>
    )
  }
}
